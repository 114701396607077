<template>
    <a @click="scrol" class="scroll-top" title="Ir arriba">
        <b-icon icon="chevron-up"></b-icon>
    </a>
</template>
<script>
import $ from "jquery";

export default {
    methods:{
       scrol(){
           $('html, body').animate({scrollTop: 0}, 600);
       }
    },
    created(){
        $(window).scroll(function() {
            if ($(this).scrollTop() > 300) {
                $('a.scroll-top').fadeIn('slow');
            } else {
                $('a.scroll-top').fadeOut('slow');
            }
        });
    }
}
</script>
<style scoped>
.scroll-top {
    cursor: pointer;
	position: fixed;
	right: 1rem;
	bottom: 1rem;
	display: none;
	width: 2.75rem;
	height: 2.75rem;
	text-align: center;
	color: #fff;
	background: rgb(0, 0, 0);
	line-height: 46px;
	z-index: 100;
  }
  
  .scroll-top:focus, .scroll-top:hover {
	color: white;
  }
  
  .scroll-top:hover {
	background: #000;
  }
  
  .scroll-top i {
	font-weight: 800;
  }
  

@media(max-width:600px) {
    .scroll-top{
        bottom: 4.5rem;
    }
}

</style>