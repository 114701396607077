import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home'
import Error from '@/views/404'
import About from '@/views/About'
import Fleet from '@/views/Fleet'
import Client from '@/views/Client'
import Transportation from '@/views/Transportation'
import Delivery from '@/views/Delivery'
import Distribution from '@/views/Distribution'
import Coldtransport from '@/views/Coldtransport'
import Motorized from '@/views/Motorized'
import Logisticssolutions from '@/views/Logisticssolutions'
import Bodywork from '@/views/Bodywork'
import Contactus from '@/views/Contactus'

Vue.use(Router)


export default new Router({
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path:'/nosotros',
      name: 'about',
      component: About
    },
    {
      path:'/flota',
      name: 'fleet',
      component: Fleet
    },
    {
      path:'/clientes',
      name:'client',
      component: Client
    },
    {
      path:'/trasporte',
      name:'transportation',
      component: Transportation
    },
    {
      path:'/ultimamilla',
      name:'delivery',
      component: Delivery
    },
    {
      path:'/distribucion',
      name:'distribution',
      component: Distribution
    },
    {
      path:'/trasportefrio',
      name:'coldtransport',
      component: Coldtransport
    },
    {
      path:'/motorizados',
      name:'motorized',
      component: Motorized
    },
    {
      path:'/solucioneslogisticas',
      name:'logisticssolutions',
      component: Logisticssolutions
    },
    {
      path:'/carroceria',
      name:'bodywork',
      component: Bodywork
    },
    {
      path:'/contactos',
      name:'contactus',
      component: Contactus
    },
    {
      path: '*', 
      name:'404',
      component: Error
    }
  ]
})