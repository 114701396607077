<template>
    <section id="banner">
        <splide :options="banner">
            <splide-slide>
              <img src="@/assets/Otros/baner6.webp">
                <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
            <img src="@/assets/Otros/entregando_caja.webp">
            <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/cargando_caja_fondo_azul.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/cargando_caja_nomada.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/gerentes_sonriendo.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/baner1.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/baner2.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/baner3.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/baner4.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            <splide-slide>
              <img src="@/assets/Otros/baner5.webp">
              <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

            
        </splide>
    </section>
</template>

<script>
  export default { 
    name: 'Caroucel',
    data() {
      return {
        banner: {
          rewind : true,
          perPage: 1,
          autoplay: true,
        },
      };
    },

  }
</script>
<style scoped>
section{
    width: 100%;
}
#banner{
    margin-top:50px;
    position: relative;
}
#banner img{
    width: 100%;
    min-height: 90vh;
    object-fit: cover;
}


#banner .contenedor{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}


.txt-titulo{
    color: #f4f4f4;
    font-size: 2.5em;
}

.txt-subtitulo{
    color: #f4f4f4;
    text-align: center;
}

@media (min-width: 1024px){
  #banner img {
    min-height: 90vh;
  }
}

@media (min-width: 768px){
  #banner img {
    min-height: 90vh;
  }
}


@media (max-width: 600px){
  .txt-titulo{
    font-size: 1.5em;
  }

  #banner img {
    min-height: 90vh;
  }
}
</style>