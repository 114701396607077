<template>
   <section>
          <px-banner 
            image="https://nomada.rightside.com.pe/img/1903x381_2.webp"
            text="Nomada Trasporte"
            title="CONTÁCTOS"
            subtitle="Logística a través de la innovación, dedicación y tecnología."
        ></px-banner>
       <px-contacus></px-contacus>
       <px-map></px-map>
   </section>
</template>
<script>
import PxBanner from "@/components/PxBanner";
import PxContacus from "@/components/PxContacus";
import PxMap from "@/components/PxMap";
export default {
    name: "transportation",
    components: {
        PxBanner,
        PxContacus,
        PxMap
    },
}
</script>