<template>
  <main>
    <px-header></px-header>

    <!--transisiones-->
      <router-view>

      </router-view>
   
    <px-footer></px-footer>
    <px-icons></px-icons>
    <px-scroll-top></px-scroll-top>
  </main>
</template>

<script>
import PxHeader from "@/components/PxHeader";

import PxFooter from "@/components/PxFooter";

import PxIcons from "@/components/PxIcons";

import PxScrollTop from "@/components/PxScrollTop";

export default {
  name: "App",
  components: {
    PxHeader,
    PxFooter,
    PxIcons,
    PxScrollTop
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
