<template>
     <a 
        class="whatsapp-button" 
        href=""
        @click.prevent="toContact()"
        >
        <img class="whatsapp-logo" src="@/assets/logo-whatsapp.webp" alt="whatsapp">
        Cotiza ahora
     </a>
</template>
<script>
export default {
    name:"Button",
    props: ['message'],
    methods:{
        toContact(){
            let message=`
                Buen día quiero mas información de *${this.message}*
            `

            let number = 957544478
            window.open(`https://api.whatsapp.com/send?phone=${number}&text=${message}`)
        }
    }
}
</script>
<style scoped>
/*BOTON*/
.whatsapp-button {
    background-color: #FFFF00;
    font-size: 1em;
    color: #000;
    display: inline-block;
    border-radius: 10px;
    padding: 5px;
}
a:hover{
    text-decoration: none;
}
.whatsapp-logo{
    width: 25%;
}      

a {
    -webkit-transform: translatey(0);
    transform: translatey(0);
    -webkit-animation: float 2s ease-in-out infinite;
    animation: float 2s ease-in-out infinite
    
}

  @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);
            transform: translatey(0)
        }
        50% {
            box-shadow: 0 20px 15px 0 rgba(0, 0, 0, .2);
            transform: translatey(-10px)
        }
        100% {
            box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);
            transform: translatey(0)
        }
    }
</style>