<template>
    <main>
        <div v-if="baners()">
            <px-carousel-mobil></px-carousel-mobil>
        </div>
        <div v-else>
            <px-carousel></px-carousel>
        </div>
        <px-services></px-services>
        <px-contacts></px-contacts>
        <px-write></px-write>
    </main>
</template>
<script>
import PxCarousel from "@/components/PxCarousel";
import PxCarouselMobil from "@/components/PxCarouselMobil"
import PxServices from "@/components/PxServices";
import PxContacts from "@/components/PxContacts";
import PxWrite from '@/components/PxWrite';



export default {
    name:'Home',
    components:{
        PxCarousel,
        PxCarouselMobil,
        PxServices,
        PxContacts,
        PxWrite
    },
    methods:{
       baners(){
           if(screen.width < 800){
				//mobil
                return true
			}
			else{
                //pc
				return false
			}
       }
    },
    created(){
        this.baners();
    }
}
</script>