<template>
    <div class="icon-bar">
        
         <a href="" class="facebook col_icons" @click.prevent="toContactFaceboock()">
            <center>  
                <div class="img-icons-logo time">
                    <img  src="@/assets/logo-facebook.webp" class="icon-size " alt="Ícono de Reloj">
                </div>
                <p class="txt-icon-horario">
                        FACEBOOK
                </p>
            </center>
        </a>

        <a href="" 
            id="icon_whatsapp" 
            class="whatsapp col_icons"
            @click.prevent="toContactWhatsapp()"
            >
                <center>  
                    <div class="img-icons-logo wsp">
                        <img src="@/assets/logowhatsapp.webp" class="icon-size "  alt="Ícono de Whatsapp">
                    </div>

                    <p class="txt-icon-horario">
                    WHATSAPP
                    </p>
                </center>
        </a>

         <a href="" 
            class="instagram col_icons" 
            @click.prevent="toContactInstagram()">
            <center>  
                <div class="img-icons-logo time">
                    <img  src="@/assets/instagram.webp" class="icon-size" alt="Ícono de Reloj">
                </div>
                <p class="txt-icon-horario">
                        INSTAGRAM
                </p>
            </center>
        </a>

        <a href="" 
            class="inkedin col_icons"
             @click.prevent="toContactInkedin()">
            <center>  
            <div class="img-icons-logo time">
                <img src="@/assets/inkedin.webp" class="icon-size " alt="Ícono de Compra">
            </div>

            <p class="txt-icon-horario">
                    LINKEDIN
            </p>
            </center>
        </a>
    </div>
</template>
<script>
export default {
    name:"Icons",
    methods:{
        toContactFaceboock(){
            window.open(`https://www.facebook.com/nomadalogistics`)
        },
        toContactWhatsapp(){
            let message=`
                Buen día quiero mas información de Servicios de trasporte,
            `

            let number = 957544478
            window.open(`https://api.whatsapp.com/send?phone=${number}&text=${message}`)
        },
        toContactInstagram(){
            window.open(`http://instagram.com/`)
        },
        toContactInkedin(){
            window.open(`https://www.linkedin.com/company/nomada-logistics/`)
        }
    }
}
</script>
<style scoped>
.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10
}

.icon-bar a {
    display: block;
    text-align: center;
    transition: all .3s ease;
    color: #fff;
    font-size: 20px;
    margin: 15px;
    border-radius: 10px;
    text-decoration-line: none;
    cursor: pointer;
}

.icon-bar a:hover{
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
}

.whatsapp {
    background: #12ba16;
    color: #fff
}
.facebook {
    background: #3b5998;
    color: #fff
}

.inkedin {
    background: #0274B3;
    color: #fff
}

.instagram{
    background: #C8319B;
    color: #fff
}

p.txt-icon-float {
	display: none;
}

p.txt-icon-horario{
	display: none;
}

img.icon-size {
    width: 30px;
    height: 30px;
}

.img-icons-logo {
    padding: 10px
}

/*RESPONSIVE*/
@media(max-width:600px) {
    .icon-bar {
        z-index: 9999
    }

    .icon-bar .barra-txt {
        font-size: 10px!important
    }

    .icon-bar .responsive_barra {
        font-size: 10px!important
    }
    .icon-bar a {
        width: 45px;
        height: 45px;
    }

    .icon-bar img {
        width: 100%;
        height: 100%
    }
    

    .img-icons-logo {
        padding: 10px;
        text-align: center;
        display: flex;
        align-items: center;
    }

     .icon-bar {
        top: inherit!important;
        bottom: -40px;
        background: #000;
        width: 100%;
        text-align: center;
        height: 70px;
    }

    .icon-bar a {
        margin: 10px;
    }

    a.facebook.col_icons {
    background: #000;
    }

    a.inkedin.col_icons{
         background: #000;
    }
    a.instagram{
        background: #000;
    }
    a.whatsapp.col_icons{
        background: #000;
    }
    .col_icons {
        display: inline-block!important;
        vertical-align: top
    }
    /*Letras de boton*/
    p.txt-icon-horario {
		display: block;
        font-size: 9px;
        margin: -6px;
	}
}
</style>