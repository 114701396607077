<template>
    <section id="banner">
        <splide :options="banner">
            <splide-slide>
              <img src="@/assets/Otros/baner6.webp">
                <div class="contenedor">
                 <center>
                  <h2 class="txt-titulo">Verdaderos Profesionales</h2>
                  <p class="txt-subtitulo">
                  Contamos con mas de 25 años en el mercado logístico,
                  siendo una empresa confiable, responsable,segura y tecnológica
                  </p>
                </center>
              </div>
            </splide-slide>

        </splide>
    </section>
</template>

<script>

  export default { 
    name: 'Caroucel',
    data() {
      return {
        banner: {
          rewind : true,
          perPage: 1,
          arrows : false,
          autoplay: true,
        },
      };
    }

  }
</script>
<style scoped>
section{
    width: 100%;
}
#banner{
    margin-top:50px;
    position: relative;
}
#banner img{
    width: 100%;
    min-height: 90vh;
    object-fit: cover;
}


#banner .contenedor{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}


.txt-titulo{
    color: #f4f4f4;
    font-size: 2.5em;
}

.txt-subtitulo{
    color: #f4f4f4;
    text-align: center;
}



</style>