<template>
    <section id="banner">
       <img :src="image" :alt="text">
            <div class="contenedor">
            <center>
            <h2 class="txt-titulo">{{ title }}</h2>
            <p class="txt-subtitulo">{{ subtitle }}</p>
            </center>
        </div>
    </section>
</template>
<script>
export default {
    props: ['image','text','title','subtitle']
}
</script>

<style scoped>
section{
    width: 100%;
}
#banner{
    margin-top:80px;
    position: relative;
}
#banner img{
    width: 100%;
    min-height: 25vh;
    object-fit: cover;
}

#banner .contenedor{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.txt-titulo{
    color: #fff;
    font-size: 1.5em;
}

.txt-subtitulo{
    color: #fff;
    text-align: center;
}
</style>