<template>
    <section>
        <footer>
            <div class="contenedor">
            <div class="container-footer-all">
                
                    <div class="container-body">

                        <div class="colum1">
                            <img class="logo-footer" src="@/assets/nomada.webp" alt="">
                        </div>

                        <div class="colum2">

                            <h1>MENÚ</h1>

                            <div class="row">
                                <img src="@/assets/flecha.webp">
                                <router-link @click.native="scrolup" to="/">Inicio</router-link>
                            </div>
                            <div class="row">
                                <img src="@/assets/flecha.webp">
                                <router-link @click.native="scrolup" to="/nosotros">Nosotros</router-link>
                            </div>
                            <div class="row">
                                <img src="@/assets/flecha.webp">
                                <router-link @click.native="scrolup" to="/trasporte">Servicios</router-link>
                            </div>
                            <div class="row">
                                <img src="@/assets/flecha.webp">
                                <router-link @click.native="scrolup" to="/contactos">Contáctos</router-link>
                            </div>
                            <div class="row">
                                <img src="@/assets/flecha.webp">
                                <router-link @click.native="scrolup" to="/clientes">Clientes</router-link>
                            </div>



                        </div>

                        <div class="colum3">

                            <h1>Redes Sociales</h1>

                            <div class="row2">
                                <img src="@/assets/flecha.webp">
                                <a href="" @click.prevent="toContactFaceboock()">Facebook</a>
                            </div>

                            <div class="row2">
                                <img src="@/assets/flecha.webp">
                                <a href="" @click.prevent="toContactLinkedin()">Linkedin</a>
                            </div>

                            <div class="row2">
                                <img src="@/assets/flecha.webp">
                                <a href="" @click.prevent="toContactInstagram()">Instagram</a>
                            </div>

                            <div class="row2">
                                <img src="@/assets/flecha.webp">
                                <a href="" @click.prevent="toContactYoutube()">Youtube</a>
                            </div>

                            <div class="row2">
                                <img src="@/assets/flecha.webp">
                                <a href="" @click.prevent="toContactTwitter()">Twitter</a>
                            </div>
                        </div>

                    </div>
                
            </div>
                
            <div class="container-footer">
                <center>
                    <div class="footer">
                        <div class="copyright">
                            © 2021 Todos los Derechos Reservados | <a href="" @click.prevent="toContactRightside()" >Right Side</a>
                        </div>
                    </div>
                </center>
            </div>
        </div>
        </footer>
    </section>
</template>
<script>
import $ from "jquery";
export default {
     name:"Footer",
    methods:{
        scrolup(){
           $('html, body').animate({scrollTop: 0}, 600);
		},
        toContactRightside(){
            window.open(`https://rightside.com.pe/`)
        },
        toContactFaceboock(){
            window.open(`https://www.facebook.com/nomadalogistics`)
        },
        toContactWhatsapp(){
            let message=`
                Buen día quiero mas información de Servicios de trasporte,
            `

            let number = 957544478
            window.open(`https://api.whatsapp.com/send?phone=${number}&text=${message}`)
        },
        toContactLinkedin(){
            window.open(`https://www.linkedin.com/company/nomada-logistics/`)
        },
        toContactInstagram(){
            window.open(`http://instagram.com/`)
        },
        toContactYoutube(){
            window.open(`http://http://youtube.com/`)
        },
        toContactTwitter(){
            window.open(`https://twitter.com/tweeter?lang=es`)
        }
    }
}
</script>
<style scoped>

.contenedor {
    padding: 30px 0;
    width: 90%;
    max-width: 1000px;
    margin: auto;
    overflow: hidden;
}

footer{
    background-color: yellow;
}

.logo-footer{
    text-align: center;
    width: 250px;
    margin: 0 auto;
}

.container-footer-all{
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 30px;
}

.container-body{
    display: flex;
    justify-content: space-between;
}

.colum1{
    max-width: 333px;
}


.colum1 h1{
    font-size: 22px;
}

.colum1 p{
    font-size: 14px;
    color: #000;
}

.colum2{
    max-width: 333px;
    
}

.colum2 h1{
    font-size: 22px;
}


.row{
    margin-top: 20px;
    display: flex;
}

.row img{
    width: 30px;
    height: 30px;
}

.row a{
    margin-left: 20px;
    color: #000;
}

.row a:hover{
    animation: swing 1s ease;
    animation-iteration-count: 1;
}

@keyframes swing {
    15% {
    transform: translateX(5px);
    }
    30% {
    transform: translateX(-5px);
    }
    50% {
    transform: translateX(3px);
    }
    65% {
    transform: translateX(-3px);
    }
    80% {
    transform: translateX(2px);
    }
    100% {
    transform: translateX(0);
    }
}

.colum3{
    max-width: 333px;
}


.colum3 h1{
    font-size: 22px;
}

.row2{
    margin-top: 20px;
    display: flex;
}

.row2 img{
    width: 30px;
    height: 30px;
}

.row2 a{
    margin-left: 20px;
    max-width: 140px;
    color: #000;
}

.row2 a:hover{
    animation: swing 1s ease;
    animation-iteration-count: 1;
}
.container-footer{
    width: 100%;  
}

.footer{
    max-width: 1000px;
    margin: auto;
    justify-content: space-between;  
}

.copyright{
    color: #000;
}

.copyright a{
    text-decoration: none;
    color: 000;
    font-weight: bold;
}


@media screen and (max-width: 1100px){
    
    .container-body{
        flex-wrap: wrap;
    }
    
    .colum1{
        max-width: 190%;
    }
    
    .colum2,
    .colum3{
        margin-top: 40px;
    }
}
@media(max-width:600px) {
    .footer{
        margin-bottom: 40px;
    }
}
</style>