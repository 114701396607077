<template>
	<header>
        <div class="contenedor">
			<router-link to="/">
				<img class="icon-logo" src="@/assets/nomada.webp" alt="Nomada Logistics">
			</router-link>
            <label for="menu-bar"  @click="main">
               <img src="@/assets/menu.webp" class="icon" alt="icon menu">
            </label>
            <nav>
                <ul>
                    <li><router-link @click.native="scrolup" to="/">INICIO</router-link></li>
					<li class="submenu" @click="change">
                        <a class="ns">NOSOTROS
							<span>
								<b-icon icon="caret-down-fill" font-scale="1"></b-icon>
							</span>
						</a>
                        <ul class="children">
							
                            <li><router-link @click.native="scrolup" to="/nosotros">¿Quiénes somos?</router-link></li>
                            <li><router-link @click.native="scrolup" to="/flota">Flota</router-link></li>
                            <li><router-link @click.native="scrolup" to="/clientes">Clientes</router-link></li>
                        </ul>
                    </li>

                    <li class="submenu2" @click="change2">
                        <a class="ns">SERVICIOS
							<span>
								<b-icon icon="caret-down-fill" font-scale="1"></b-icon>
							</span>
						</a>
                        <ul class="children">
                            <li><router-link @click.native="scrolup" to="/trasporte">Transporte</router-link></li>
                            <li><router-link @click.native="scrolup" to="/ultimamilla">Última Milla</router-link></li>
                            <li><router-link @click.native="scrolup" to="/distribucion">Distribución</router-link></li>
							<li><router-link @click.native="scrolup" to="/trasportefrio">Transporte en frío</router-link></li>
							<li><router-link @click.native="scrolup" to="/motorizados">Motorizados</router-link></li>
							<li><router-link @click.native="scrolup" to="/solucioneslogisticas">Soluciones logísticas</router-link></li>
                        </ul>
                    </li>
                    
                    <li><router-link @click.native="scrolup" to="/carroceria">CARROCERÍA</router-link></li>
                    <li><a href="" @click.prevent="toContact()">COTIZA</a></li>
					<li><router-link @click.native="scrolup" to="/contactos">CONTÁCTANOS</router-link></li>
                </ul>
            </nav>
        </div>
	
	</header>
	
</template>
<script>
import $ from "jquery";
var contador = 1;
export default {
		name:"Header",
		methods:{
		scrolup(){
           $('html, body').animate({scrollTop: 0}, 600);if (contador == 1) {
			$('nav').animate({
				left: '0'
			});
			contador = 0;
			} else {
				contador = 1;
				$('nav').animate({
					left: '-100%'
				});
			}
		},
        change(){
			if(screen.width < 800){
				$('.submenu').children('.children').slideToggle();
			}
			else{
				$('.submenu');
			}
        },
		change2(){
			if(screen.width < 800){
				$('.submenu2').children('.children').slideToggle();
			}
			else{
				$('.submenu2');
			}
		},
        main(){
            if (contador == 1) {
			$('nav').animate({
				left: '0'
			});
			contador = 0;
			} else {
				contador = 1;
				$('nav').animate({
					left: '-100%'
				});
			}
        },
		toContact(){
            let message=`
                Buen día quiero mas información de sus servicios de trasporte
            `

            let number = 957544478
            window.open(`https://api.whatsapp.com/send?phone=${number}&text=${message}`)
        }
    }
}
</script>
<style scoped>

 .contenedor{
    width: 100%;
    margin: auto;
    max-width: 1000px;
}

header{
    width: 100%;
    height: 80px;
    background:#fff;
    color: #000;
    /*Se quede pegado*/
    position: fixed;
    top: 0;
    left: 0;
    /*Siempre por delante*/
    z-index: 100;
}

.icon-logo{
    width: 150px;
    float: left;
    margin: 25px;
}

.icon{
	background: yellow;
	padding: 5px;
}

.ns{
	cursor: pointer;
}

.menu_bar {
	display:none;
}
 
header {
	width: 100%;
}
 
 header label{
    float: right;
    font-size: 30px;
    cursor: pointer;
}

header nav {
	z-index:1000;
	max-width: 1000px;
	width:100%;
}
 
header nav ul {
	list-style:none;
}
 
header nav ul li {
	display:inline-block;
	position: relative;
}
 
header nav ul li:hover {
	background:yellow;
}
 
header nav ul li a {
	color:#000;
	display:block;
	text-decoration:none;
	padding: 10px;
	height: 60px;
	margin-top: 20px;
}
 

header nav ul li a span {
	margin-right:10px;
}
 
header nav ul li:hover .children {
	display:block;
}
 
header nav ul li .children {
	display: none;
	background: yellow;
	position: absolute;
	width: 150%;
	z-index:1000;
}



header nav ul li .children a:hover {
	background: white;
}

header nav ul li .children li {
	display:block;
	overflow: hidden;
	border-bottom: 1px solid rgba(255,255,255,.5);
}
 
header nav ul li .children li a {
	display: block;
	margin-top: 0px;
	padding: 10px;
	height: 40px;
}
 
header nav ul li .children li a span {
	float: right;
	position: relative;
	top:3px;
	margin-right:0;
	margin-left:10px;
}
 
header nav ul li .caret {
	position: relative;
	top:3px;
	margin-left:10px;
	margin-right:0px;
}
 
 @media(min-width:1024px){
     header nav {
        position: static;
        width: auto;
        height: auto;
         float: right;
         display: flex;
    }

    .menu a{
        border: none;
    }
    header label{
        display: none;
    }
}
@media screen and (max-width: 800px) {
 
	.menu_bar {
		display:block;
		width:100%;
		position: fixed;
		top:0;
		background:yellow;
	}
 
	header nav{
		background: rgba(255, 255, 255, 0.9);
	}
	.menu_bar .bt-menu {
		display: block;
		padding: 20px;
		color: #fff;
		overflow: hidden;
		font-size: 25px;
		font-weight: bold;
		text-decoration: none;
	}
 
	.menu_bar span {
		float: right;
		font-size: 40px;
	}
 
	header nav {
		width: 100%;
		/*height: calc(100% - 80px);*/
		position: fixed;
		right:100%;
		margin: 0;
        top: 80px;
		overflow: scroll;
		height: 100vh;
	}
 
	header nav ul li {
		display: block;
		border-bottom:1px solid rgba(255,255,255,.5);
	}
 
	header nav ul li a {
		display: block;
	}
 
	header nav ul li:hover .children {
		display: none;
	}
 
	header nav ul li .children {
		width: 100%;
		position: relative;
	}
 
	header nav ul li .children li a {
		margin-left:20px;
	}
 
	header nav ul li .caret {
		float: right;
	}
}
</style>