<template>
    <section>
         <px-banner 
            image="https://nomada.rightside.com.pe/img/1903x381_2.webp"
            text="Nomada Trasporte"
            title="NOSOTROS"
            subtitle="Logística a través de la innovación, dedicación y tecnología."
        ></px-banner>
        <div class="contenedor portafolio">
                <h2 id="sintomas" class="titulo">Clientes</h2>
                <div class="galeria-port">
                    <div class="imagen-port">
                        <img class="imagen" src="@/assets/Clientes/Falabella.webp" alt="Falabella">
                    </div>
                    <div class="imagen-port">
                        <img class="imagen" src="@/assets/Clientes/Oechsle.webp" alt="Oechsle">
                    </div>
                    <div class="imagen-port">
                        <img class="imagen" src="@/assets/Clientes/Tottus.webp" alt="Tottus">
                    </div>
                    <div class="imagen-port">
                        <img class="imagen" src="@/assets/Clientes/Ripley.webp" alt="Ripley">
                    </div>
                    
                    <div class="imagen-port">
                        <img class="imagen" src="@/assets/Clientes/GrupoEfe.webp" alt="Grupo efe">
                    </div>
                </div>
         </div>
        </section>
</template>
<script>
import PxBanner from "@/components/PxBanner";

export default {
    name:'Client',
        components: {
        PxBanner
    },
}
</script>
<style scoped>
.contenedor {
    padding: 30px 0;
    width: 90%;
    max-width: 1000px;
    margin: auto;
    overflow: hidden;
}
.titulo {
    color: #000;
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
}

.galeria-port{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.imagen-port{
    width: 30%;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    /*
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .5);
    */
}

.imagen-port > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.hover-galeria{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transform: scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hover-galeria img{
    width: 50px;
}


.hover-galeria p{
    color: #000;
}
img{
    transition: all 0.2s ease-in-out;
}

img:hover{
box-shadow: 1px 1px #000, 2px 2px #000, 3px 3px #000;
transform: translateX(-3px);
}

/*
Animacion
*/
.imagen{
    animation-duration: 3s;
    animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 200%
  }

  to {
    margin-left: 0%;
    width: 90%;
  }
}

@media screen and (max-width:900px){
    .imagen-port{
        width: 44%;
    }
}
@media screen and (max-width:500px){
    .imagen-port{
        width: 95%;
    }
}
</style>